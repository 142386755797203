<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div class="modal">

          <section class="modal-body">
            <button
              type="button"
              class="btn-close"
              @click="close"
            >
              x
            </button>

            <div class="project-photo">
                <slot name="ProjectImage">
                    projectImage!
                </slot>
            </div>
          </section>

      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'ProjectPhotoModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
    z-index: 999;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }  

  .project-photo {
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 1366px) {
  
    .modal {
      width: 90vw;
      height: 57vh;
      justify-content: center;
    }

  }

  @media screen and (max-width: 1366px) and (orientation: landscape) {

    .modal {
      width: 40%;
      height: auto;
    }

  }

</style>