<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
          <header class="modal-header">
              <div class="project-top">
                <slot name="projectDescription">
                    projectDescription!
                </slot>
              </div>
              <button
                type="button"
                class="btn-close"
                @click="close"
              >
                x
              </button>
          </header>

          <section class="modal-body">
            <div class="project-bottom">
                <slot name="allProjectImages">
                    allProjectImages!
                </slot>
            </div>
          </section>

      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'ProjectModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    display: flex;
    flex-direction: column;
    width: 65vw;
    height: 85vh;
  }

  .modal-header {
    position: relative;
    padding: 15px;
    display: flex;    
    justify-content: space-between;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    overflow: auto;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
    z-index: 999;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }  

  .project-top {
    width: 90%;
    text-align: center;
  }

  .project-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: 1366px) {
  

  }

</style>